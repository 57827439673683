body {
    background-color: #d6dde2;
    color: #144663;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-wrap {
    flex: 1;
}

h2 {
    margin-bottom: 2rem;
}

h3 {
    color: #009bde;
    text-align: center;
}

h5 {
    margin: 0px;
}

.bg-dark {
    background-color: #b22028 !important;
}

.bg-dark-top {
    background-color: #006098 !important;
}

.bg-dark-left {
    background-color: #ffa400;
}

.jumbotron {
    background-color: #fff;
    padding: 2rem 0 4rem;
}

.form-group {
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
}

.btn-primary {
    border-color: #b22028;
    background-color: #b22028;
    /*color : white !important;*/
}

.list-group-item {
    background-color: #b22028;
    border: 1px solid #fff;
    color: #fff;
}

    .list-group-item:hover {
        background-color: #0069d9 !important;
    }


.calculation-list {
    background-color: #006098 !important;
}

.card-header {
    padding: 20px !important;
   
    
}

.calculation-list:hover {
    background-color: #b22028 !important;
    color: #fff;
}

.calculation-list:focus{
    color: #fff;
}


.card-results {
    font-weight: bold;
    background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) );
}
/*NAV-BAR*/
.navbar {
    margin-bottom: 20px;
    padding: 0 1rem;
}


.navbar-toggler {
    margin: .5rem 0;
}


html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.text-dark {
    color: #ffffff !important;
}

/*REMOVE UNDERLINE FROM LINKS/BUTTONS*/
a:hover {
    text-decoration: none;
}


.list-group-item {
    background-color: #b22028;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
}

/*FORMULA DEFINITION*/
.formula-definition {
    background-color: #7d868c;
    border: none !important;
}

.answer-card-red {
    background-color: #ff0000;
    height: 100%;
    border-radius: 0.25rem;
}

.answer-card-green {
    background-color: green;
    height: 100%;
    border-radius: 0.25rem;
}

.answer-card-grey {
    background-color: #7d868c;
    height: 100%;
    border-radius: 0.25rem;
}

/*.card-header {
    font-weight: bold;
    background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) );
}*/
.def {
    color: #144663 !important;
    font-weight: bold;
    text-align: justify;
}

.bodyshop {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 0px 0px 0px;
}

.pdf-button {
    background-color: #545b62 !important;
    color: #fff;
    width: 60%;
    margin: 10px;
}

    .pdf-button:hover {
        color: #fff;
    }

    .pdf-button:focus {
        color: #fff;
    }

.test {
    padding-left: 0px;
    margin: 10px 0px 0px -5px;
}

@media screen and (max-width: 960px) {
    .right {
        margin: 10px 0px 0px -5px;
    }
}

/*FOOTER*/

.footer-container {
    background-color: #d6dde2;
    /*padding: 4rem 0 2rem 0;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.version-number {
    color: #9e9e9e;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}
